import { useAuth } from "../AuthContext";
import { useAsync, useTitle } from "react-use";
import { ErrorDisplay, Loading } from "../utils";
import { useCallback, useState } from "react";
import { checkLoginVk, startLoginVk, useFetch, VkCheckLoginResult } from "../api";

export function Profile() {
  useTitle("Profile | lectory-web");
  const { fetch } = useFetch();

  const [checkLoginResultVk, setCheckLoginResultVk] = useState<{ result: VkCheckLoginResult } | { error: Error } | null>(null);
  const doCheckLoginVk = useCallback(async () => {
    try {
      setCheckLoginResultVk({ result: await checkLoginVk({ fetch }) });
    } catch (error: any) {
      setCheckLoginResultVk({ error });
    }
  }, [fetch]);
  const doLoginVk = useCallback(async () => {
    const { redirect_uri } = await startLoginVk({ fetch });
    window.location.href = redirect_uri;
  }, [fetch]);

  const auth = useAuth();
  const { value: identity, error, loading } = useAsync(() => auth.identity(), [auth]);
  if (error) {
    return <ErrorDisplay error={error} />
  }
  if (loading || !identity) {
    return <Loading />
  }
  const { profile } = identity;

  return (
    <>
      <h3>Profile</h3>
      <p>Username: {profile.preferred_username}</p>
      <p>Display name: {profile.display_name}</p>
      <p>Application roles:</p>
      <ul>
        {profile.roles.map((role: string) => (
          <li key={role}>{role}</li>
        ))}
      </ul>
      <p>Groups:</p>
      <ul>
        {profile.groups.map((group: string) => (
          <li key={group}>{group}</li>
        ))}
      </ul>

      <h3>VK</h3>
      <p>
        Actions
        {' '}
        <button onClick={doCheckLoginVk}>Check login status</button>
        {' '}
        <button onClick={doLoginVk}>Re-login</button>
      </p>
      {checkLoginResultVk && 'result' in checkLoginResultVk && (
        <>
          <p>User ID: {checkLoginResultVk.result.user_id}</p>
          <p>Device ID: {checkLoginResultVk.result.device_id}</p>
          <p>Scope: {checkLoginResultVk.result.scope}</p>
          <p>Expires: {new Date(checkLoginResultVk.result.expires_at * 1000).toLocaleString()}</p>
        </>
      )}
      {checkLoginResultVk && 'error' in checkLoginResultVk && (
        <>
          <p>Error: {checkLoginResultVk.error.name}: {checkLoginResultVk.error.message}</p>
        </>
      )}
    </>
  );
}
